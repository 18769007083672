document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "stories") {
        let tableStories;
        let emotion_id;
        let emotion_ids = []
        if ($("#story_emotion_ids").val() != undefined)
            emotion_ids = $("#story_emotion_ids").val().split(",").map((num) => parseInt(num));

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableStories != null) {
                tableStories.destroy();
            }
        })

        tableStories = $('#table-stories').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            'columnDefs': [
                {
                   'targets': 0,
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                    }
                }
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });
        $("#table-stories_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left")
        $("#table-stories_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete filter-passed'>" + 
                "<input type='checkbox' id='filter-show_not_active' checked>" + 
                "<label for='filter-show_not_active'>Show inactive stories</label>" +
            "</div>" + 
        "</span></div>")

        // toggle active/inactive stories
        $("#filter-show_not_active").on("change", function() {
            if($(this).prop("checked")) {
                $.fn.dataTable.ext.search.pop();
            }
            else {
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                       return $(tableStories.row(dataIndex).node()).attr('data-active') == 'true';
                    }
                 );
            }

            tableStories.draw();
        })

        $('[data-init-plugin="select2"]').each(function () {
            $(this).select2({
                minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
            });
        });
        
        const lightbox = GLightbox();
        
        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
     
        const pond_image = FilePond.create(document.querySelector('#story-image'), {
            acceptedFileTypes: ['image/png', 'image/jpg', 'image/jpeg'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinHeight: 128,
            imagePreviewMaxHeight: 128,
            imagePreviewHeight: 128,
            allowProcess: false,
            instantUpload: false,
            name: 'story[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 300000,
        });

        const pond_file = FilePond.create(document.querySelector('#story-file'), {
            acceptedFileTypes: ['audio/mp4', 'audio/mp3', 'audio/*', 'audio/mpeg3'],
            labelIdle: "Drag & Drop your audio file or <span class='filepond--label-action'> Browse </span>",
            allowProcess: false,
            instantUpload: false,
            name: 'story[file]',
            storeAsFile: true,
            credits: {label: "", url: ""}
        });

        $("#btn-save-story").on("click", function() {
            $("#form-story").validate()

            if ($("#form-story").valid()) {
                $(".loader").removeClass("hidden")
                $(".btn-cancel").attr("disabled", true)
                $("button[type='submit']").attr("disabled", true)
                
                document.getElementById("form-story").submit()
            }
        })

        // delete story
        $(".delete-story").on("click", function() {
            story_id = $(this).parent().parent().attr("data-story_id")

            Swal.fire({
                title: 'Are you sure?',
                text: "The audio file will be deleted as well!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                    if (result.isConfirmed) {
                        destroy(story_id, null)
                    }
              })
        })

        // change state
        $(".change-state").on("click", function() {
            let text = $(this).text() == "true" ? 'Inactive' : 'Active'
            let state = $(this).text() == "true" ? false : true

            Swal.fire({
                title: `Change this story's state to ${text} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    story_id = $(this).parent().parent().attr("data-story_id")
                    changeState(story_id, state)
                }
            })
        })

        // change state selected elements
        $("#state-selected-elements").on("click", function() {
            Swal.fire({
                title: "Change all selected elements state to ?",
                input: 'select',
                inputOptions: {
                  'true': 'Active',
                  'false': 'Inactive'
                },
                inputPlaceholder: 'Select a state',
                showCancelButton: true,
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                        if (value == "")
                            resolve("Please choose a state")
                        else {
                            changeState(null, value, rows_selected.join(","))
                        }
                    })
                }
            })
        });

        //select elements
        $("#table-stories input[type=checkbox]").on("change", function() {
            refresh_selected_elements()
        })
        
        // delete selected elements
        $("#delete-selected-elements").on("click", function() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    elements = rows_selected
                    $.each(elements, function(index, rowId){
                        $(`#table-stories tr[data-story_id='${rowId}'] td:nth-child(7)`).html("<b>Deleting...</b>");
                        $(`#table-stories tr[data-story_id='${rowId}'] td:nth-child(6) a`).removeAttr("href")
                        $(`#table-stories tr[data-story_id='${rowId}'] .change-state`).off( "click", "");
                        tableStories.cells(`tr[data-story_id='${rowId}']`, 0).checkboxes.disable()
                    })

                    destroy(null, rows_selected.join(","))
                }
            })
        })

        function changeState(story_id, state, elements) {
            if (story_id != null) {
                $.ajax({
                    url: "/stories/change_state/" + story_id,
                    type: 'PUT',
                    dataType: "json",
                    data: {
                        state: state
                    },
                    success: function (data) {
                        $(`#table-stories tr[data-story_id='${story_id}'] .change-state`).text(state);
                        $(`#table-stories tr[data-story_id='${story_id}']`).toggleClass("tr-deactivated")
                        $(`#table-stories tr[data-story_id='${story_id}']`).attr("data-active", state)
                        if (state) {
                            $(`#table-stories tr[data-story_id='${story_id}'] .change-state`).addClass("badge-success")
                            $(`#table-stories tr[data-story_id='${story_id}'] .change-state`).removeClass("badge-warning")
                        }
                        else {
                            $(`#table-stories tr[data-story_id='${story_id}'] .change-state`).addClass("badge-warning")
                            $(`#table-stories tr[data-story_id='${story_id}'] .change-state`).removeClass("badge-success")
                        }
                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null) {
                $(".total-selected-content").addClass("hidden")

                $.ajax({
                    url: "/stories/change_state",
                    type: 'PUT',
                    dataType: "json",
                    data: {
                        elements_id: elements,
                        state: state
                    },
                    success: function (data) {
                        elements = elements.split(',')
                        
                        $.each(elements, function(index, rowId){
                            $(`#table-stories tr[data-story_id='${rowId}'] .change-state`).text(state);
                            $(`#table-stories tr[data-story_id='${rowId}']`).attr("data-active", state)
                            tableStories.cells(`tr[data-story_id='${rowId}']`, 0).checkboxes.deselect()

                            
                            if (state == "true") {
                                $(`#table-stories tr[data-story_id='${rowId}'] .change-state`).addClass("badge-success")
                                $(`#table-stories tr[data-story_id='${rowId}'] .change-state`).removeClass("badge-warning")

                                $(`#table-stories tr[data-story_id='${rowId}']`).removeClass("tr-deactivated")
                            }
                            else {
                                $(`#table-stories tr[data-story_id='${rowId}'] .change-state`).addClass("badge-warning")
                                $(`#table-stories tr[data-story_id='${rowId}'] .change-state`).removeClass("badge-success")
                                
                                $(`#table-stories tr[data-story_id='${rowId}']`).addClass("tr-deactivated")
                            }
                            
                        });

                        refresh_selected_elements()

                        Swal.close()
                    },
                    error: function(data) {
                    }
                });
            }
        }

        function destroy(story_id, elements) {
            if (story_id != null) {
                $(`#table-stories tr[data-story_id='${story_id}'] td:nth-child(7)`).html("<b>Deleting...</b>");
                $(`#table-stories tr[data-story_id='${story_id}'] td:nth-child(6) a`).removeAttr("href");
                $(`#table-stories tr[data-story_id='${story_id}']`).addClass("tr-deleting");
                $(`#table-stories tr[data-story_id='${story_id}'] .change-state`).off( "click", "");
                
                tableStories.cells(`tr[data-story_id='${story_id}']`, 0).checkboxes.disable()
                refresh_selected_elements()

                $.ajax({
                    headers: {  'Access-Control-Allow-Origin': 'http://tomanina.01.tn' },
                    url: "/stories/" + story_id,
                    type: 'DELETE',
                    dataType: "json",
                    crossDomain: true,
                    success: function (data) {
                        tableStories.row($(`tr[data-story_id='${story_id}']`)).remove().draw();

                        if (data.name != null) {
                            messageDeleted = `The story "${data.title}" has been deleted.`
                        }
                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null){
                $(".total-selected-content").addClass("hidden")

                $.ajax({
                    url: "/stories/delete_elements",
                    type: 'DELETE',
                    dataType: "json",
                    data: {
                        elements_id: elements
                    },
                    success: function (data) {
                        elements = elements.split(',')

                        $.each(elements, function(index, rowId){
                            tableStories.row($(`tr[data-story_id='${rowId}']`)).remove().draw();
                        });

                        $(".total-selected-content").addClass("hidden")
                    },
                    error: function(data) {
                    }
                });
            }
        }

        // select emotion
        $(".select-emotion").on("click", function() {
            $(this).toggleClass("emotion_selected")
            emotion_id = $(this).parent().data("emotion_id")
            if (emotion_ids.indexOf(emotion_id) != -1) {
                emotion_ids.splice(emotion_ids.indexOf(emotion_id), 1);
            }
            else {
                emotion_ids.push(emotion_id)
            }
            
            $("#story_emotion_ids").val(emotion_ids)

            $(".emotion_txt").removeClass("emotion_bold")
            $(this).next(".emotion_txt").toggleClass("emotion_bold")
        })

        function refresh_selected_elements() {
            rows_selected = tableStories.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
            
            $.each(rows_selected, function(index, rowId){
            });

            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)

            return rows_selected
        }
    }
})