document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "application") {
        
    }


    if($("body").data("controller") == "home") {

        tableUsers = $('#table-apple_notification').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'columnDefs': [
               // { "targets": 4, "visible": false },
            ],
            'order' : [[0,'desc']],
            "iDisplayLength": 30,
        });

        
        $(document).on("click", "#btn-show_payload", function() {
            let id = $(this).attr("data-notif_id")
            $(".payload").text("")

            fetch(`/users/get-payload/${id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        console.log(data)
                        $(".payload").text(data.payload)
                        $("#modal-payload").modal("show")
                    })
                }
                else {
                    response.json().then(function(data) {

                    })
                }
            })
        })
    }
})