// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'
import "lightbox2"

// Global
require("jquery");
require("popper.js");
require("moment");
require("select2")
require("bootstrap");
require("sweetalert2")
require("jquery-validation")
require("filepond")
require("packs/global/jquery.scrollbar.min")
require("packs/global/modernizr.custom");
require("packs/global/sidebar")
require("packs/global/classie")
require("packs/global/selectFx")
require("packs/global/pages")
require("packs/global/jquery.dataTables.min")
require("packs/global/dataTables.checkboxes.min")
require("packs/global/dataTables.bootstrap")
require("packs/global/autoNumeric")
require("lightbox2")

// App
require("packs/app/admin_user");
require("packs/app/covers");
require("packs/app/tags");
require("packs/app/emotions");
require("packs/app/users");
require("packs/app/videos");
require("packs/app/games");
require("packs/app/songs");
require("packs/app/stories");
require("packs/app/activities");
require("packs/app/membership_plans");
require("packs/app/orders")
require("packs/app/home")
require("packs/app/payment_histories")
require("packs/global/bootstrap-datepicker");

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import jQueryScrollbar from 'jquery.scrollbar';
window.jQueryScrollbar = jQueryScrollbar;

import * as FilePond from 'filepond'
window.filepond = FilePond

import Swal from 'sweetalert2';
window.Swal = Swal;


window.STRIPE_DASHBOARD_PAYMENT= "https://dashboard.stripe.com/payments"
window.STRIPE_DASHBOARD_SUB= "https://dashboard.stripe.com/subscriptions"

// import VideoUploader from '@api.video/video-uploader';

$("#btn-save-slider").on('click', function() {
  $(".alert-home_sliders").remove()
})