document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "covers") {
        let tableCovers;
        let rows_selected;

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableCovers != null) {
                tableCovers.destroy();
            }
        })

        tableCovers = $('#table-covers').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            
            'columnDefs': [
                {
                   'targets': 0,
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                    },
                    "orderable": false
                }
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            "destroy": true,
            "scrollCollapse": true,
            "stateSave": false,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });
        $("#table-covers_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left")
        
        $("#table-covers_wrapper > .row > div:first").append("<!--<div><span class='filter-planning_passed'>" +
            "<div class='form-check checkbox-circle complete filter-passed'>" + 
                "<input type='checkbox' id='filter-worksite_finished' checked>" + 
                "<label for='filter-worksite_finished'></label>" +
            "</div>" + 
        "</span></div>-->")
       
        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
     
        const pond = FilePond.create(document.querySelector('input[type="file"]'), {
            acceptedFileTypes: ['image/png', 'image/jpg', 'image/jpeg'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinHeight: 64,
            imagePreviewMaxHeight: 64,
            imagePreviewHeight: 64,
            allowProcess: false,
            instantUpload: false,
            name: 'cover[photo]',
            allowReorder: true,
            storeAsFile: true,
            credits: {label: "", url: ""},
            maxFileSize: 300000
        });
        
        document.addEventListener('FilePond:addfile', (e) => {
            $(".alert-danger").hide()
        });
        
        $("#btn-save-cover").on("click", function() {
            $("#form-cover").validate()

            if ($("#form-cover").valid()) {
                $(".loader").removeClass("hidden")
                $(".btn-cancel").attr("disabled", true)
                $("button[type='submit']").attr("disabled", true)
                
                document.getElementById("form-cover").submit()
            }
        })

        // $(".edit-cover").on("click", function(e) {
        //     cover_id = $(this).parent().parent().attr("data-cover_id")

        //     window.location = "/covers/" + cover_id + "/edit"
        // })

        //select elements
        $("#table-covers input[type=checkbox]").on("change", function() {
            refresh_selected_elements()
        })
        
        // delete selected elements
        $("#delete-selected-elements").on("click", function() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    elements = rows_selected
                    $.each(elements, function(index, rowId){
                        $(`#table-covers tr[data-cover_id='${rowId}'] td:nth-child(6)`).html("<b>Deleting...</b>");
                        $(`#table-covers tr[data-cover_id='${rowId}'] .change-state`).off( "click", "");
                        tableCovers.cells(`tr[data-cover_id='${rowId}']`, 0).checkboxes.disable()
                    })

                    destroy(null, rows_selected.join(","))
                }
            })
        })

        $(".delete-cover").on("click", function() {
            cover_id = $(this).parent().parent().attr("data-cover_id")

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    $(`#table-covers tr[data-cover_id='${cover_id}'] td:nth-child(6)`).html("<b>Deleting...</b>");

                    destroy(cover_id, null)
                }
            })
        })

        function destroy(cover_id, elements) {
            if (cover_id != null) {
                
                $(`#table-covers tr[data-cover_id='${cover_id}']`).addClass("tr-deleting")

                tableCovers.cells(`tr[data-cover_id='${cover_id}']`, 0).checkboxes.disable()
                refresh_selected_elements()

                $.ajax({
                    url: "/covers/" + cover_id,
                    type: 'DELETE',
                    dataType: "json",
                    success: function (data) {
                        tableCovers.row($(`tr[data-cover_id='${cover_id}']`)).remove().draw();
                        refresh_selected_elements()
                       
                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null) {
                $(".total-selected-content").addClass("hidden")

                $.ajax({
                    url: "/covers/delete_elements",
                    type: 'DELETE',
                    dataType: "json",
                    data: {
                        elements_id: elements
                    },
                    success: function (data) {
                        elements = elements.split(',')

                        $.each(elements, function(index, rowId){
                            tableCovers.row($(`tr[data-cover_id='${rowId}']`)).remove().draw();
                        });

                        $(".total-selected-content").addClass("hidden")
                    },
                    error: function(data) {
                    }
                });
            }
        }

        function refresh_selected_elements() {
            rows_selected = tableCovers.column(0).checkboxes.selected();
       
            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }

            $.each(rows_selected, function(index, rowId){
            });

            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)

            return rows_selected
        }
    }
})