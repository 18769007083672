const { video } = require("modernizr");

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "videos") {
        let tableVideos;
        let tableVideosUndone;
        let cancelUploading = false;
        let emotion_id = null;
        let emotion_ids = []
        if ($("#video_emotion_ids").val() != undefined)
            emotion_ids = $("#video_emotion_ids").val().split(",").map((num) => parseInt(num));

        document.addEventListener("turbolinks:before-cache", function() {
            if (tableVideos != null) {
                tableVideos.destroy();
            }
            if (tableVideosUndone != null) {
                tableVideosUndone.destroy();
            }
        })

        tableVideos = $('#table-videos').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            'columnDefs': [
                {
                   'targets': 0,
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                    }
                }
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });
        $("#table-videos_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left")
        $("#table-videos_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete filter-passed'>" + 
                "<input type='checkbox' id='filter-show_not_active' checked>" + 
                "<label for='filter-show_not_active'>Show inactive videos</label>" +
            "</div>" + 
        "</span></div>")

        // $("#table-videos_wrapper > .row > div:first").append( 
        //     ""
        // )
    
        $("#filter-show_not_active").on("change", function() {
            if($(this).prop("checked")) {
                $.fn.dataTable.ext.search.pop();
            }
            else {
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                       return $(tableVideos.row(dataIndex).node()).attr('data-active') == 'true';
                    }
                 );
            }

            tableVideos.draw();
        })

        tableVideosUndone = $('#table-videos_undone').DataTable({
            "bPaginate": true,
            "bInfo": false,
            "bLengthChange": false,
            "destroy": true,
            "searching": false,
            "scrollCollapse": true,
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });

        $('[data-init-plugin="select2"]').each(function () {
            $(this).select2({
                minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
            });
        });
        
        const lightbox = GLightbox();
        
        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
     
        const pond = FilePond.create(document.querySelector('#video-image'), {
            acceptedFileTypes: ['image/png', 'image/jpg', 'image/jpeg'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinHeight: 128,
            imagePreviewMaxHeight: 128,
            imagePreviewHeight: 128,
            allowProcess: false,
            instantUpload: false,
            name: 'video[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 300000,
        });
            
        // const client = new ApiVideoClient({ apiKey: "aMqcs3Q4bO4WoHMKAWMmrHHkHmoJ9aBqeyo43x97Vjf" });

        // const videoId = 'vi2o0vpKZbwQZBgSGxhJPAOf'; // the id of the video to delete
        // client.videos.delete(videoId); 

        // const uploader = new ProgressiveUploader({
        //     accessToken: "aMqcs3Q4bO4WoHMKAWMmrHHkHmoJ9aBqeyo43x97Vjf",
        //     videoId: "vivcYlL9pIuBpGIa1EwrOuD"
        // });

        // uploader.onProgress((event) => {
        //     console.log(`total number of bytes uploaded for this upload: ${event.uploadedBytes}.`);
        //     console.log(`total size of the file: ${event.totalBytes}.`);
        //     console.log(`number of upload chunks: ${event.chunksCount} .`);
        //     console.log(`size of a chunk: ${event.chunksBytes}.`);
        //     console.log(`index of the chunk being uploaded: ${event.currentChunk}.`);
        //     console.log(`number of bytes uploaded for the current chunk: ${event.currentChunkUploadedBytes}.`);
        // });

        // document.getElementById("input_btn").addEventListener('click',function()
        // {
        //     document.getElementById("video-input").click(); 
        // }, false);

        $("#video-input").on("click", function() {
            document.getElementById("upfile").click();
        })
        
        $("#upfile").on("change", function(obj) {
            var video_id = $(".video-success-id").text();
            var file_origine = this
            var file = this.value;
            if (file != "") {
                if (isVideo(this.value)) {
                    var fileName = file.split("\\");
                    document.getElementById("video-input").innerHTML = fileName[fileName.length - 1];
                    $(this).attr("disabled", true)

                    $.ajax({
                        url: "/videos/prepare_video",
                        type: 'PUT',
                        dataType: "json",
                        data: {
                            video_id: video_id
                        },
                        success: function (data) {
                            $("#video-id").val(data.video_id)
                            upload(file_origine, data.video_id)
                        },
                        error: function(data) {
                        }
                    });
                }
                else {
                    $(".video-upload-error").removeClass("hidden")
                }
            }
        })

        $("#btn-save-video").on("click", function() {
            $("#form-video").validate()

            if ($("#form-video").valid()) {
                $(".loader").removeClass("hidden")
                $(".btn-cancel").attr("disabled", true)
                $("button[type='submit']").attr("disabled", true)

                document.getElementById("form-video").submit()
            }
        })

        // cancel uploading
        $("#video-cancel_uploading").on("click", function() { 
            Swal.fire({
                title: 'Are you sure?',
                text: "The video will be deleted from the server!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        url: "/videos/cancel_uploading/" + $("#video-id").val(),
                        type: 'DELETE',
                        dataType: "json",
                        success: function (data) {
                            cancelUploading = true
                        },
                        error: function(data) {
                        }
                    });
                }
            })
        })

        // change state selected elements
        $("#state-selected-elements").on("click", function() {
            Swal.fire({
                title: "Change all selected elements state to ?",
                input: 'select',
                inputOptions: {
                  'true': 'Active',
                  'false': 'Inactive'
                },
                inputPlaceholder: 'Select a state',
                showCancelButton: true,
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                        if (value == "")
                            resolve("Please choose a state")
                        else {
                            changeState(null, value, rows_selected.join(","))
                        }
                  })
                }
              })
        });

        // change state
        $(".change-state").on("click", function() {
            let text = $(this).text() == "true" ? 'Inactive' : 'Active'
            let state = $(this).text() == "true" ? false : true

            Swal.fire({
                title: `Change this video's state to ${text} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    video_id = $(this).parent().parent().attr("data-video_id")
                    changeState(video_id, state)
                }
            })
        })

        function changeState(video_id, state, elements) {
            if (video_id != null) {
                $.ajax({
                    url: "/videos/change_state/" + video_id,
                    type: 'PUT',
                    dataType: "json",
                    data: {
                        state: state
                    },
                    success: function (data) {
                        $(`#table-videos tr[data-video_id='${video_id}'] .change-state`).text(state);
                        $(`#table-videos tr[data-video_id='${video_id}']`).toggleClass("tr-deactivated")
                        $(`#table-videos tr[data-video_id='${video_id}']`).attr("data-active", state)
                        if (state) {
                            $(`#table-videos tr[data-video_id='${video_id}'] .change-state`).addClass("badge-success")
                            $(`#table-videos tr[data-video_id='${video_id}'] .change-state`).removeClass("badge-warning")
                        }
                        else {
                            $(`#table-videos tr[data-video_id='${video_id}'] .change-state`).addClass("badge-warning")
                            $(`#table-videos tr[data-video_id='${video_id}'] .change-state`).removeClass("badge-success")
                        }
                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null) {
                $(".total-selected-content").addClass("hidden")

                $.ajax({
                    url: "/videos/change_state",
                    type: 'PUT',
                    dataType: "json",
                    data: {
                        elements_id: elements,
                        state: state
                    },
                    success: function (data) {
                        elements = elements.split(',')
                        
                        $.each(elements, function(index, rowId){
                            $(`#table-videos tr[data-video_id='${rowId}'] .change-state`).text(state);
                            $(`#table-videos tr[data-video_id='${rowId}']`).attr("data-active", state)
                            tableVideos.cells(`tr[data-video_id='${rowId}']`, 0).checkboxes.deselect()
                         
                            if (state == "true") {
                                $(`#table-videos tr[data-video_id='${rowId}'] .change-state`).addClass("badge-success")
                                $(`#table-videos tr[data-video_id='${rowId}'] .change-state`).removeClass("badge-warning")

                                $(`#table-videos tr[data-video_id='${rowId}']`).removeClass("tr-deactivated")
                            }
                            else {
                                $(`#table-videos tr[data-video_id='${rowId}'] .change-state`).addClass("badge-warning")
                                $(`#table-videos tr[data-video_id='${rowId}'] .change-state`).removeClass("badge-success")
                                $(`#table-videos tr[data-video_id='${rowId}']`).addClass("tr-deactivated")
                            }
                            
                        });

                        refresh_selected_elements()

                        Swal.close()
                    },
                    error: function(data) {
                    }
                });
            }
        }

        // delete video
        $(".delete-video").on("click", function() {
            video_id = $(this).parent().parent().attr("data-video_id")

            Swal.fire({
                title: 'Are you sure?',
                text: "The video will be deleted from the server!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                    if (result.isConfirmed) {
                        destroy(video_id, null)
                    }
              })
        })

        //select elements
        $("#table-videos input[type=checkbox]").on("change", function() {
            refresh_selected_elements()
        })
        $("#table-videos_undone input[type=checkbox]").on("change", function() {
            refresh_selected_elements()
        })
        
        // delete selected elements
        $("#delete-selected-elements").on("click", function() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    elements = rows_selected
                    $.each(elements, function(index, rowId){
                        $(`#table-videos tr[data-video_id='${rowId}'] td:nth-child(7)`).html("<b>Deleting...</b>");
                        $(`#table-videos tr[data-video_id='${rowId}'] td:nth-child(6) a`).removeAttr("href")
                        $(`#table-videos tr[data-video_id='${rowId}'] .change-state`).off( "click", "");
                        tableVideos.cells(`tr[data-video_id='${rowId}']`, 0).checkboxes.disable()
                    })

                    destroy(null, rows_selected.join(","))
                }
            })
        })
        
        function upload(file, video_id) {
            
            $(".video-progress").removeClass("hidden")
            $(".video-progress .progress").append("<div class='video-progress-upload-length text-center w-100 text-center' style='position: absolute; font-weight: bold;''>0%</div>" +
                "<div class='progress-bar progress-bar-striped progress-bar-animated video-progress-upload' role='progressbar' aria-valuenow='75' aria-valuemin='0' aria-valuemax='100' style='margin-right: auto; width: 0%'>" +
            "</div>")

            var uploader = new VideoUploader({
                videoId: video_id,
                file: file.files[0],
                uploadToken: "to5pYv2Li3irOb9HwHTdhkE6",
                retries: 10000000
            })

            uploader.upload().then((video) => {
                $(".video-success-info").removeClass("hidden")
                $(".video-success-id").text(video.videoId)
                $(".video-success-url").text(video.assets.mp4)
                $(".video-success-url").parent().attr("href", video.assets.mp4) 
               
                //$("#video-player").html(video.assets.iframe) 

                $("#video-url").val(video.assets.mp4)
                $("#video-id").val(video.videoId)

                $(".video-progress").addClass("hidden")
                $(".video-upload-success").removeClass("hidden")
                $("#btn-save-video").attr("disabled", false)
                $("#upfile").attr("disabled", false)
            })
            .catch((error) => console.log(error.status, error.message));

            uploader.onProgress((event) => {

                if (cancelUploading) {
                    $(".video-progress .progress div").remove()
                    resetUpload()
                    cancelUploading = false
                }

                percent = (event.uploadedBytes / event.totalBytes) * 100
                percent = Number.parseFloat(percent).toFixed(2)

                if (percent > 100) {
                    percent = 100
                }

                $(".video-progress-upload").css("width", percent  + "%")
                $(".video-progress-upload-length").text(percent  + "%")
            })
            
        }

        function destroy(video_id, elements) {
            if (video_id != null) {
                $(`#table-videos tr[data-video_id='${video_id}'] td:nth-child(7)`).html("<b>Deleting...</b>");
                $(`#table-videos tr[data-video_id='${video_id}'] td:nth-child(6) a`).removeAttr("href")
                $(`#table-videos tr[data-video_id='${video_id}']`).addClass("tr-deleting")
                $(`#table-videos tr[data-video_id='${video_id}'] .change-state`).off( "click", "");

                $(`#table-videos_undone tr[data-video_id='${video_id}'] td:nth-child(3)`).html("<b>Deleting...</b>");
                $(`#table-videos_undone tr[data-video_id='${video_id}'] td:nth-child(2) a`).removeAttr("href")
                $(`#table-videos_undone tr[data-video_id='${video_id}']`).addClass("tr-deleting")
                
                tableVideos.cells(`tr[data-video_id='${video_id}']`, 0).checkboxes.disable()
                refresh_selected_elements()

                $.ajax({
                    url: "/videos/" + video_id,
                    type: 'DELETE',
                    dataType: "json",
                    success: function (data) {
                        tableVideos.row($(`tr[data-video_id='${video_id}']`)).remove().draw();
                        tableVideosUndone.row($(`tr[data-video_id='${video_id}']`)).remove().draw();

                        if (data.title != null) {
                            messageDeleted = `The video "${data.title}" has been deleted.`
                        }
                        else {
                            messageDeleted = "The incompleted video has been deleted."
                        }
                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null) {
                $(".total-selected-content").addClass("hidden")

                $.ajax({
                    url: "/videos/delete_elements",
                    type: 'DELETE',
                    dataType: "json",
                    data: {
                        elements_id: elements
                    },
                    success: function (data) {
                        elements = elements.split(',')

                        $.each(elements, function(index, rowId){
                            tableVideos.row($(`tr[data-video_id='${rowId}']`)).remove().draw();
                        });

                        $(".total-selected-content").addClass("hidden")
                    },
                    error: function(data) {
                    }
                });
            }
        }

        // select emotion
        $(".select-emotion").on("click", function() {
            $(this).toggleClass("emotion_selected")
            emotion_id = $(this).parent().data("emotion_id")
            if (emotion_ids.indexOf(emotion_id) != -1) {
                emotion_ids.splice(emotion_ids.indexOf(emotion_id), 1);
            }
            else {
                emotion_ids.push(emotion_id)
            }
            
            $("#video_emotion_ids").val(emotion_ids)

            $(".emotion_txt").removeClass("emotion_bold")
            $(this).next(".emotion_txt").toggleClass("emotion_bold")
        })

        function resetUpload() {
            $("#video-id").val("")
            $(".video-progress").addClass("hidden")
            $(".video-success-info").addClass("hidden")
            $(".video-progress-upload").css("width", "0%")
            $(".video-progress-upload-length").text("0%")
            $(".video-upload-error").addClass("hidden")
            $("#upfile").attr("disabled", false)
            $("#video-input").text("CLICK TO CHOOSE A FILE")
        }

        function isVideo(filename) {
            var parts = filename.split('.');
            ext = parts[parts.length - 1];

            switch (ext.toLowerCase()) {
              case 'm4v':
              case 'avi':
              case 'mpg':
              case 'mp4':
                // etc
                return true;
            }
            return false;
        }

        function refresh_selected_elements() {
            rows_selected = tableVideos.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
            
            $.each(rows_selected, function(index, rowId){
            });

            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)

            return rows_selected
        }
        
    }
})