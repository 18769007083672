document.addEventListener("turbolinks:load", function() {

    if($("body").data("controller") == "users") {
        // const stripe = Stripe('pk_test_51LicINL9nKHMFVm4BKTOep9VEpsk55BoGVsqqf2CGm2vjYIDmMNvzlGMXlHzyR48FOnjHGP62ZELaWsXikVWXLxG00rF8NyTzM');

		// const options = {
		// 	clientSecret: $("#client_secret").val(),
		// 	appearance: {
        //         theme: 'stripe',
        //     }
		// }

		// const elements = stripe.elements(options);
		// const paymentElement = elements.create('payment');
		// paymentElement.mount('#payment-element');

		// const form = document.getElementById('payment-form');
    }
})
