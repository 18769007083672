document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "emotions") {
        let tableEmotions;
        document.addEventListener("turbolinks:before-cache", function() {
            if (tableEmotions != null) {
                tableEmotions.destroy();
            }
        })

        tableEmotions = $('#table-emotions').DataTable({
            'initComplete': function(settings){
                var api = this.api();
                
                api.cells(
                    api.rows(function(idx, data, node){
                        return ($(node).data('related')) ? true : false;
                    }).indexes(),
                    0
                ).checkboxes.disable();
            },
            'columnDefs': [
                {
                   'targets': 0, 
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                    },
                    "createdCell": function(td, cellData, rowData, row, col) {
                    }
                },
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "bSearching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });
        $("#table-emotions_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left")
        
        $("#table-emotions_wrapper > .row > div:first").append("<div><span>" +
        "<div class='form-check checkbox-circle complete filter-passed'>" + 
            "<input type='checkbox' id='filter-show_not_active' checked>" + 
            "<label for='filter-show_not_active'>Show inactive emotions</label>" +
        "</div>" + 
        "</span></div>")

        

        // toggle active/inactive emotions
        $("#filter-show_not_active").on("change", function() {
            if($(this).prop("checked")) {
                $.fn.dataTable.ext.search.pop();
            }
            else {
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                       return $(tableEmotions.row(dataIndex).node()).attr('data-active') == 'true';
                    }
                 );
            }

            tableEmotions.draw();
        })

        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
     
        const pond = FilePond.create(document.querySelector('input[type="file"]'), {
            acceptedFileTypes: ['image/png', 'image/jpg', 'image/jpeg'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinHeight: 64,
            imagePreviewMaxHeight: 64,
            imagePreviewHeight: 64,
            allowProcess: false,
            instantUpload: false,
            name: 'emotion[image]',
            allowReorder: true,
            storeAsFile: true,
            credits: {label: "", url: ""},
            maxFileSize: 300000
        });

        $("#btn-save-emotion").on("click", function() {
            $("#form-emotion").validate()

            if ($("#form-emotion").valid()) {
                $(".loader").removeClass("hidden")
                $(".btn-cancel").attr("disabled", true)
                $("button[type='submit']").attr("disabled", true)

                document.getElementById("form-emotion").submit()
            }
        })

        //select elements
        $("#table-emotions input[type=checkbox]").on("change", function() {
            refresh_selected_elements()
        })

        // delete selected elements
        $("#delete-selected-elements").on("click", function() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    elements = rows_selected
                    $.each(elements, function(index, rowId){
                        $(`#table-emotions tr[data-emotion_id='${rowId}'] td:nth-child(7)`).html("<b>Deleting...</b>");
                        $(`#table-emotions tr[data-emotion_id='${rowId}'] .change-state`).off( "click", "");
                        tableEmotions.cells(`tr[data-emotion_id='${rowId}']`, 0).checkboxes.disable()
                    })

                    destroy(null, rows_selected.join(","))
                }
            })
        })

        // delete emotion
        $(".delete-emotion").on("click", function() {
            emotion_id = $(this).parent().parent().attr("data-emotion_id")

            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                    if (result.isConfirmed) {
                        destroy(emotion_id, null)
                    }
              })
        })

        // change state selected elements
        $("#state-selected-elements").on("click", function() {
            Swal.fire({
                title: "Change all selected elements state to ?",
                input: 'select',
                inputOptions: {
                  'true': 'Active',
                  'false': 'Inactive'
                },
                inputPlaceholder: 'Select a state',
                showCancelButton: true,
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                        if (value == "")
                            resolve("Please choose a state")
                        else {
                            changeState(null, value, rows_selected.join(","))
                        }
                    })
                }
            })
        });

        // change state
        $(".change-state").on("click", function() {
            let text = $(this).text() == "true" ? 'Inactive' : 'Active'
            let state = $(this).text() == "true" ? false : true

            Swal.fire({
                title: `Change this emotion's state to ${text} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!'
              }).then((result) => {
                    if (result.isConfirmed) {
                        emotion_id = $(this).parent().parent().attr("data-emotion_id")
                        changeState(emotion_id, state)
                    }
              })
        })

        function destroy(emotion_id, elements) {
            if (emotion_id != null) {
                $(`#table-emotions tr[data-emotion_id='${emotion_id}'] td:nth-child(7)`).html("<b>Deleting...</b>");
                $(`#table-emotions tr[data-emotion_id='${emotion_id}'] .change-state`).off( "click", "");
                $(`#table-emotions tr[data-emotion_id='${emotion_id}']`).addClass("tr-deleting")

                tableEmotions.cells(`tr[data-emotion_id='${emotion_id}']`, 0).checkboxes.disable()
                refresh_selected_elements()
                
                $.ajax({
                    url: "/emotions/" + emotion_id,
                    type: 'DELETE',
                    dataType: "json",
                    success: function (data) {
                        tableEmotions.row($(`tr[data-emotion_id='${emotion_id}']`)).remove().draw();

                        if (data.name != null) {
                            messageDeleted = `The emotion "${data.title}" has been deleted.`
                        }
                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null) {
                $(".total-selected-content").addClass("hidden")
                $.ajax({
                    url: "/emotions/delete_elements",
                    type: 'DELETE',
                    dataType: "json",
                    data: {
                        elements_id: elements
                    },
                    success: function (data) {
                        elements = elements.split(',')

                        $.each(elements, function(index, rowId){
                            tableEmotions.row($(`tr[data-emotion_id='${rowId}']`)).remove().draw();
                        });

                        $(".total-selected-content").addClass("hidden")
                    },
                    error: function(data) {
                    }
                });
            }
        }

        function changeState(emotion_id, state, elements) {
            if (emotion_id != null) {
                $.ajax({
                    url: "/emotions/change_state/" + emotion_id,
                    type: 'PUT',
                    dataType: "json",
                    data: {
                        state: state
                    },
                    success: function (data) {
                        $(`#table-emotions tr[data-emotion_id='${emotion_id}'] .change-state`).text(state);
                        $(`#table-emotions tr[data-emotion_id='${emotion_id}']`).toggleClass("tr-deactivated")
                        $(`#table-emotions tr[data-emotion_id='${emotion_id}']`).attr("data-active", state)
                        if (state) {
                            $(`#table-emotions tr[data-emotion_id='${emotion_id}'] .change-state`).addClass("badge-success")
                            $(`#table-emotions tr[data-emotion_id='${emotion_id}'] .change-state`).removeClass("badge-warning")
                        }
                        else {
                            $(`#table-emotions tr[data-emotion_id='${emotion_id}'] .change-state`).addClass("badge-warning")
                            $(`#table-emotions tr[data-emotion_id='${emotion_id}'] .change-state`).removeClass("badge-success")
                        }
                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null) {
                $(".total-selected-content").addClass("hidden")

                $.ajax({
                    url: "/emotions/change_state",
                    type: 'PUT',
                    dataType: "json",
                    data: {
                        elements_id: elements,
                        state: state
                    },
                    success: function (data) {
                        elements = elements.split(',')
                        
                        $.each(elements, function(index, rowId){
                            $(`#table-emotions tr[data-emotion_id='${rowId}'] .change-state`).text(state);
                            $(`#table-emotions tr[data-emotion_id='${rowId}']`).attr("data-active", state)
                            tableEmotions.cells(`tr[data-emotion_id='${rowId}']`, 0).checkboxes.deselect()

                            if (state == "true") {
                                $(`#table-emotions tr[data-emotion_id='${rowId}'] .change-state`).addClass("badge-success")
                                $(`#table-emotions tr[data-emotion_id='${rowId}'] .change-state`).removeClass("badge-warning")

                                $(`#table-emotions tr[data-emotion_id='${rowId}']`).removeClass("tr-deactivated")
                            }
                            else {
                                $(`#table-emotions tr[data-emotion_id='${rowId}'] .change-state`).addClass("badge-warning")
                                $(`#table-emotions tr[data-emotion_id='${rowId}'] .change-state`).removeClass("badge-success")
                                
                                $(`#table-emotions tr[data-emotion_id='${rowId}']`).addClass("tr-deactivated")
                            }
                            
                        });

                        refresh_selected_elements()

                        Swal.close()
                    },
                    error: function(data) {
                    }
                });
            }
        }

        function refresh_selected_elements() {
            rows_selected = tableEmotions.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
            
            $.each(rows_selected, function(index, rowId){
            });

            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)

            return rows_selected
        }
    }
})