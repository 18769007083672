document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "membership_plans") {
        let tableMembershipPlans;
        
        document.addEventListener("turbolinks:before-cache", function() {
            if (tableMembershipPlans != null) {
                tableMembershipPlans.destroy();
            }
        })

        tableMembershipPlans = $('#table-membership_plans').DataTable({
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "stateSave": false,
            "destroy": true,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
        $("#table-membership_plans_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left")

        $('.autonumeric').autoNumeric('init');

        // delete membership
        $(".delete-membership_plan").on("click", function(e) {
            element_id = $(this).parent().parent().data('membership_plan_id')

            let title = "Delete Membership plan"
            let text = "Are you sure you want to delete this Membership plan? This can't be undone."
            let buttonText = "Delete membership plan"
            let url = `/membership_plans/${element_id}`
            let method = 'DELETE'
            
            const formData = new FormData();

            Swal.fire({
                title: title,
                text: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: buttonText,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(url, {
                        method: method,
                        mode: 'cors',
                        cache: 'default',
                        body: formData
                    }).then(function(response) {
                        if (response.ok) {
                            tableMembershipPlans.row($(`tr[data-membership_plan_id='${element_id}']`)).remove().draw();
                        }
                       
                    })
                }
            }).then((result) => {})
        })

        $(".archive-element").on("click", function() {
            element_id = $(this).parent().parent().attr("data-membership_plan_id")

            archive(element_id)
        })


        function archive(id) {
            const formData = new FormData();

            title = "Archive Membership plan"
            text = "Archiving will hide this Membership plan from new subscriptions. Are you sure you want to archive this?"
            buttonText = "Archive membership plan"
            url = `/membership_plans/archive/${element_id}`
            method = 'PUT'

            formData.append('active', false);
            
            Swal.fire({
                title: title,
                text: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: buttonText,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(url, {
                        method: method,
                        mode: 'cors',
                        cache: 'default',
                        body: formData
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                console.log(data)
                                $(`#table-membership_plans tr[data-membership_plan_id='${data.id}'] td:nth-child(4)`).text('inactive')
                            })
                            //tableMembershipPlans.row($(`tr[data-membership_plan_id='${element_id}']`)).remove().draw();
                        }
                       
                    })
                }
            }).then((result) => {})
        }

    }
})