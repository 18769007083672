document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "tags") {
        let tableTags;
        
        document.addEventListener("turbolinks:before-cache", function() {
            if (tableTags != null) {
                tableTags.destroy();
            }
        })

        tableTags = $('#table-tags').DataTable({
            'initComplete': function(settings){
                var api = this.api();
       
                api.cells(
                   api.rows(function(idx, data, node){
                      return ($(node).data('related')) ? true : false;
                   }).indexes(),
                   0
                ).checkboxes.disable();
            },
            'columnDefs': [
                {
                   'targets': 0,
                   'checkboxes': {
                        'selectRow': false,
                        'selectCallback': function(nodes, selected) {
                            refresh_selected_elements()
                        }
                    }
                }
            ],
            'select': {
                'style': 'multi',
                "selector": 'td:first-child'
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });
        $("#table-tags_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left")
        $("#table-tags_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete filter-passed'>" + 
                "<input type='checkbox' id='filter-show_not_active' checked>" + 
                "<label for='filter-show_not_active'>Show inactive tags</label>" +
            "</div>" + 
        "</span></div>")

        $("#filter-show_not_active").on("change", function() {
            if($(this).prop("checked")) {
                $.fn.dataTable.ext.search.pop();
            }
            else {
                $.fn.dataTable.ext.search.push(
                    function(settings, data, dataIndex) {
                       return $(tableTags.row(dataIndex).node()).attr('data-active') == 'true';
                    }
                 );
            }

            tableTags.draw();
        })

        $("#btn-save-tag").on("click", function() {
            $("#form-tag").validate()

            if ($("#form-tag").valid()) {
                $(".loader").removeClass("hidden")
                $(".btn-cancel").attr("href", "javascript:void(0)")
                $(".btn-cancel").attr("disabled", true)
            }
        })

        // delete tag
        $(".delete-tag").on("click", function() {
            tag_id = $(this).parent().parent().attr("data-tag_id")

            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                    if (result.isConfirmed) {
                        destroy(tag_id, null)
                    }
              })
        })

        //select elements
        $("#table-tags input[type=checkbox]").on("change", function() {
            refresh_selected_elements()
        })
        
        // change state selected elements
        $("#state-selected-elements").on("click", function() {
            Swal.fire({
                title: "Change all selected elements state to ?",
                input: 'select',
                inputOptions: {
                  'true': 'Active',
                  'false': 'Inactive'
                },
                inputPlaceholder: 'Select a state',
                showCancelButton: true,
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                        if (value == "")
                            resolve("Please choose a state")
                        else {
                            changeState(null, value, rows_selected.join(","))
                        }
                    })
                }
            })
        });

        // delete selected elements
        $("#delete-selected-elements").on("click", function() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    elements = rows_selected
                    $.each(elements, function(index, rowId){
                        $(`#table-tags tr[data-tag_id='${rowId}'] td:nth-child(5)`).html("<b>Deleting...</b>");
                        $(`#table-tags tr[data-tag_id='${rowId}'] .change-state`).off( "click", "");
                        tableTags.cells(`tr[data-tag_id='${rowId}']`, 0).checkboxes.disable()
                    })

                    destroy(null, rows_selected.join(","))
                }
            })
        })
        
        // change state
        $(".change-state").on("click", function() {
            let text = $(this).text() == "true" ? 'Inactive' : 'Active'
            let state = $(this).text() == "true" ? false : true

            Swal.fire({
                title: `Change this tag's state to ${text} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!'
              }).then((result) => {
                    if (result.isConfirmed) {
                        tag_id = $(this).parent().parent().attr("data-tag_id")
                        changeState(tag_id, state)
                    }
              })
        })

        function changeState(tag_id, state, elements) {
            if (tag_id != null) {
                $.ajax({
                    url: "/tags/change_state/" + tag_id,
                    type: 'PUT',
                    dataType: "json",
                    data: {
                        state: state
                    },
                    success: function (data) {
                        $(`#table-tags tr[data-tag_id='${tag_id}'] .change-state`).text(state);
                        $(`#table-tags tr[data-tag_id='${tag_id}']`).toggleClass("tr-deactivated")
                        $(`#table-tags tr[data-tag_id='${tag_id}']`).attr("data-active", state)
                        if (state) {
                            $(`#table-tags tr[data-tag_id='${tag_id}'] .change-state`).addClass("badge-success")
                            $(`#table-tags tr[data-tag_id='${tag_id}'] .change-state`).removeClass("badge-warning")
                        }
                        else {
                            $(`#table-tags tr[data-tag_id='${tag_id}'] .change-state`).addClass("badge-warning")
                            $(`#table-tags tr[data-tag_id='${tag_id}'] .change-state`).removeClass("badge-success")
                        }
                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null) {
                $(".total-selected-content").addClass("hidden")

                $.ajax({
                    url: "/tags/change_state",
                    type: 'PUT',
                    dataType: "json",
                    data: {
                        elements_id: elements,
                        state: state
                    },
                    success: function (data) {
                        elements = elements.split(',')
                        
                        $.each(elements, function(index, rowId){
                            $(`#table-tags tr[data-tag_id='${rowId}'] .change-state`).text(state);
                            $(`#table-tags tr[data-tag_id='${rowId}']`).attr("data-active", state)
                            tableTags.cells(`tr[data-tag_id='${rowId}']`, 0).checkboxes.deselect()

                            if (state == "true") {
                                $(`#table-tags tr[data-tag_id='${rowId}'] .change-state`).addClass("badge-success")
                                $(`#table-tags tr[data-tag_id='${rowId}'] .change-state`).removeClass("badge-warning")

                                $(`#table-tags tr[data-tag_id='${rowId}']`).removeClass("tr-deactivated")
                            }
                            else {
                                $(`#table-tags tr[data-tag_id='${rowId}'] .change-state`).addClass("badge-warning")
                                $(`#table-tags tr[data-tag_id='${rowId}'] .change-state`).removeClass("badge-success")
                                
                                $(`#table-tags tr[data-tag_id='${rowId}']`).addClass("tr-deactivated")
                            }
                            
                        });

                        refresh_selected_elements()

                        Swal.close()
                    },
                    error: function(data) {
                    }
                });
            }
        }

        function destroy(tag_id, elements) {
            if (tag_id != null) {
                $(`#table-tags tr[data-tag_id='${tag_id}'] td:nth-child(5)`).html("<b>Deleting...</b>");
                $(`#table-tags tr[data-tag_id='${tag_id}'] .change-state`).off( "click", "");
                $(`#table-tags tr[data-tag_id='${tag_id}']`).addClass("tr-deleting")

                tableTags.cells(`tr[data-tag_id='${tag_id}']`, 0).checkboxes.disable()
                refresh_selected_elements()

                $.ajax({
                    url: "/tags/" + tag_id,
                    type: 'DELETE',
                    dataType: "json",
                    success: function (data) {
                        tableTags.row($(`tr[data-tag_id='${tag_id}']`)).remove().draw();

                        if (data.name != null) {
                            messageDeleted = `The tag "${data.title}" has been deleted.`
                        }

                    },
                    error: function(data) {
                    }
                });
            }
            else if (elements != null)
            {
                $(".total-selected-content").addClass("hidden")

                $.ajax({
                    url: "/tags/delete_elements",
                    type: 'DELETE',
                    dataType: "json",
                    data: {
                        elements_id: elements
                    },
                    success: function (data) {
                        elements = elements.split(',')

                        $.each(elements, function(index, rowId){
                            tableTags.row($(`tr[data-tag_id='${rowId}']`)).remove().draw();
                        });

                        $(".total-selected-content").addClass("hidden")
                    },
                    error: function(data) {
                    }
                });
            }
        }

        function refresh_selected_elements() {
            rows_selected = tableTags.column(0).checkboxes.selected();

            if (rows_selected.length == 0) {
                $(".total-selected-content").addClass("hidden")
                $(".total-selected-content").css("marginBottom", 0)
                $(".total-selected-content").css("opacity", 0)
            }
            else {
                $(".total-selected-content").removeClass("hidden")
                $(".total-selected-content").animate({
                    marginBottom: 10,
                    opacity: 1
                }, 100, "linear",  function() {
                });
            }
            
            $.each(rows_selected, function(index, rowId){
            });

            let txt = rows_selected.length > 1 ? `${rows_selected.length} elements selected` : `${rows_selected.length} element selected`
            $(".total-selected").text(txt)

            return rows_selected
        }
        
    }
})